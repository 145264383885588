// src/main.js
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import { Auth0Plugin } from './auth';
import MainPage from './components/MainPage.vue';
import CsvUploadPage from './components/CsvUploadPage.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: MainPage,
    },
    {
      path: '/upload-csv',
      name: 'upload-csv',
      component: CsvUploadPage,
    },
    // other routes...
  ],
});

const app = createApp(App);

app.use(Auth0Plugin);
app.use(router);

app.mount('#app');