<template>
  <div id="app">
    <header>
      <img alt="logo" src="https://littlegroup.xyz/assets/img/logo-green.png" class="logo">
      <nav>
        <router-link to="/">Home</router-link>
          <router-link v-if="isAuthenticated" to="/upload-csv">Upload CSV</router-link>

        <!-- Add more navigation links as needed -->
      </nav>
      <button v-if="!isAuthenticated" @click="login">Log in</button>
      <button v-if="isAuthenticated" @click="logout">Log out</button>
    </header>
    <MainPage msg="Domain Portfolio Tracking" :isAuthenticated="isAuthenticated" />
  </div>
</template>

<script>
import MainPage from './components/MainPage.vue';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'App',
  components: {
    MainPage,
  },
  setup() {
    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

    const login = () => {
      loginWithRedirect();
    };

    const handleLogout = () => {
      logout();
    };

    return {
      isAuthenticated,
      login,
      logout: handleLogout,
    };
  },
};
</script>

<style>
@import url('styles.css');
#app {
  font-family: Ubuntu, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
margin-left: 10px;
margin-right: 10px;
  background-color: #F8F9FA;
}

header {
  background-color: #f8f9fa;
  padding: 10px 10px 10px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  width: 80px;
}

nav {
  margin-left: auto;
}

button {
  background-color: #134B1C; /* Add your desired button color */
  color: #fff;
  padding: 8px 12px;
  margin: 5px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
}

button:hover {
  background-color: 124b1c; /* Add your desired hover color */
}

.button-text {
  display: inline;
}

button:hover .button-text {
  display: inline;
}

</style>
