<template>
  <div>
    <h1>Upload CSV</h1>
    <input type="file" @change="handleFileUpload" accept=".csv" />
  </div>
</template>

<script>
import { handleFileUpload } from '../utils/csvUtils';

export default {
  name: 'CsvUploadPage',
  methods: {
    handleFileUpload,
  },
};
</script>