// src/utils/domainUtils.js
import axios from 'axios';

export const loadTrackedDomains = async (user, trackedDomains) => {
  if (user.value && user.value.sub) {
    const userId = user.value.sub;
    const kvUrl = `https://tracker-worker.littlecloud.workers.dev?userId=${userId}`;

    const response = await axios.get(kvUrl);
    if (response.data && Array.isArray(response.data)) {
      trackedDomains.value = response.data.map(item => JSON.parse(item));
    }
  }
};

export const saveTrackedDomains = async (user, domainInfo) => {
  try {
    const userId = user.value.sub;
    const kvUrl = `https://tracker-worker.littlecloud.workers.dev?userId=${userId}`;

    // Save tracked domains to Cloudflare Workers KV
    const response = await axios.post(kvUrl, {
      action: 'trackDomain',
      domainInfo: domainInfo,
      userId: userId,
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response;
  } catch (error) {
    console.error('Error saving tracked domains:', error.message);
  }
};

export const fetchDomainInfo = async (newDomain, trackedDomains, saveTrackedDomains, user) => {
  try {
    const apiUrl = 'https://www.whoisxmlapi.com/whoisserver/WhoisService';
    const { data: xmlResponse } = await axios.get(apiUrl, {
      params: { apiKey: 'at_QJpS6BZmYTTmIB2pnY4XMWCGOLwL1', domainName: newDomain.value }
    });

    // Parse the XML response
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlResponse, "text/xml");

    // Extract the domain information from the XML
    const domainNameElement = xmlDoc.getElementsByTagName("domainName")[0];
    const expiresDateElement = xmlDoc.getElementsByTagName("expiresDate")[0];
    const nameServersElement = xmlDoc.getElementsByTagName("nameServers")[0];
    const nameserversElement = nameServersElement.getElementsByTagName("hostNames")[0];
    const nameservers = Array.from(nameserversElement.getElementsByTagName("Address")).map(el => el.textContent);
    const registrarNameElement = xmlDoc.getElementsByTagName("registrarName")[0];

    if (!domainNameElement || !expiresDateElement) {
      throw new Error('Required element not found in the XML response');
    }

    const expiresDate = expiresDateElement.textContent;
    const registrarName = registrarNameElement.textContent;

    // Create a new domain object
    const domainInfo = {
      id: newDomain.value,
      expiresDate: expiresDate,
      nameservers: nameservers,
      registrarName: registrarName,
      rawOutput: xmlResponse
    };

    // Add the new domain to the trackedDomains array
    trackedDomains.value.push(domainInfo);

    // Save the updated trackedDomains array
    await saveTrackedDomains(user, domainInfo);

    // Clear the form
    newDomain.value = '';

  } catch (error) {
    console.error('Error fetching domain information:', error.message);
  }
};

export const removeDomain = async (domainId, trackedDomains, user) => {
  const index = trackedDomains.value.findIndex((domain) => domain.id === domainId);
  if (index !== -1) {
    try {
      const userId = user.value.sub;
      const response = await axios.delete(`https://tracker-worker.littlecloud.workers.dev?userId=${userId}`, {
        data: {
          id: domainId,
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        trackedDomains.value.splice(index, 1);
      } else {
        console.error('Error deleting domain:', response.data);
      }
    } catch (error) {
      console.error('Error deleting domain:', error.message);
    }
  }
};

export const refreshDomainInfo = async (domainId, trackedDomains, saveTrackedDomains, user) => {
  try {
    const apiUrl = 'https://www.whoisxmlapi.com/whoisserver/WhoisService';
    const { data: xmlResponse } = await axios.get(apiUrl, {
      params: { apiKey: 'at_QJpS6BZmYTTmIB2pnY4XMWCGOLwL1', domainName: domainId }
    });

    // Parse the XML response

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlResponse, "text/xml");
    
        // Extract the domain information from the XML
        const domainNameElement = xmlDoc.getElementsByTagName("domainName")[0];
        const expiresDateElement = xmlDoc.getElementsByTagName("expiresDate")[0];
        const nameServersElement = xmlDoc.getElementsByTagName("nameServers")[0];
        const nameserversElement = nameServersElement.getElementsByTagName("hostNames")[0];
        const nameservers = Array.from(nameserversElement.getElementsByTagName("Address")).map(el => el.textContent);
        const registrarNameElement = xmlDoc.getElementsByTagName("registrarName")[0];
    
        if (!domainNameElement || !expiresDateElement) {
          throw new Error('Required element not found in the XML response');
        }
    
        const expiresDate = expiresDateElement.textContent;
        const registrarName = registrarNameElement.textContent;
    
        // Find the domain in the trackedDomains array and update its info
        const updatedDomains = trackedDomains.value.map(domain => {
          if (domain.id === domainId) {
            return {
              ...domain,
              expiresDate: expiresDate,
              nameservers: nameservers,
              registrarName: registrarName,
              rawOutput: xmlResponse
            };
          }
          return domain;
        });
    
        // Save the updated trackedDomains array
        trackedDomains.value = updatedDomains;
        await saveTrackedDomains(user, updatedDomains);
      } catch (error) {
        console.error('Error refreshing domain information:', error.message);
      }
    };