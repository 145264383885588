// src/auth.js
import { createAuth0 } from '@auth0/auth0-vue';

export const Auth0Plugin = createAuth0({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_AUTH0_REDIRECT_URI,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  redirectUri: window.location.origin,
});
