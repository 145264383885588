// csvUtils.js
import { ref } from 'vue';

// Define trackedDomains as a ref
export const trackedDomains = ref([]);

// Function to handle file upload
export const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = e.target.result;
      processCSVData(csv);
    };
    reader.readAsText(file);
  }
};

// Function to process CSV data
const processCSVData = (csv) => {
  const domainNames = parseCSV(csv);
  addDomainsToTrackedList(domainNames);
};

// Function to parse CSV data and extract domain names
const parseCSV = (csv) => {
  const lines = csv.split('\n');
  const domainNames = lines.map(line => line.trim());
  return domainNames;
};

// Function to add domain names to the tracked list
const addDomainsToTrackedList = (domainNames) => {
  trackedDomains.value.push(...domainNames);
};