<template>
  <div>
    <h1>Little Domain Tracker</h1>
    <p>Track your domain portfolio and know where everything is</p>
 <p v-if="isAuthenticated"> Submit your domain to track it</p>
    <!-- Domain Tracking Form (visible only to authenticated users) -->
    <form v-if="isAuthenticated" @submit.prevent="fetchDomainInfo" class="tracking-form">
      <label for="domain">Domain:</label>
      <input v-model="newDomain" type="text" id="domain" required />
      <button type="submit">Track Domain</button>
    </form>

    <!-- List of Tracked Domains -->
    <div v-if="isAuthenticated && trackedDomains.length > 0" class="tracked-domains">
      <h2>Tracked Domains:</h2>
      <table class="tracked-domains-table">
        <thead>
          <tr>
            <th>Domain</th>
            <th>Expires On</th>
            <th>Registrar</th>
            <th>NS Records</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="domain in trackedDomains" :key="domain.id" class="tracked-domain">
            <td>
              <strong>{{ domain.id }}</strong>
            </td>
            <td>{{ domain.expiresDate }}</td>
            <td v-if="domain.registrarName">{{ domain.registrarName }}</td>
            <td v-else>N/A</td>
            <td v-if="domain.nameservers">{{ domain.nameservers }}</td>
            <td v-else>N/A</td>
            <td>
              <button @click="toggleRawOutput(domain.id)">
  <i class="fas fa-eye"></i> <span class="button-text">Show Raw Output</span>
</button>
<button @click="refreshDomainInfo(domain.id)" class="refresh-button">
  <i class="fas fa-recycle-alt"></i> <span class="button-text">Refresh</span>
</button>
<button @click="removeDomain(domain.id)" class="delete-button">
  <i class="fas fa-trash-alt"></i> <span class="button-text">Delete</span>
</button>
              <div v-if="showRawOutput === domain.id" class="modal">
                <div class="modal-content">
                  <span class="close" @click="toggleRawOutput(domain.id)">&times;</span>
                  <pre class="raw-output">{{ domain.rawOutput }}</pre>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
C:\Users\james_littlewebco\Documents\GitHub\domain-tracker\src\components\MainPage.vue
  65:3  error  'trackedDomains' is defined but never used  no-unused-vars
  66:3  error  'newDomain' is defined but never used       no-unused-vars
  67:3  error  'showRawOutput' is defined but never used   no-unused-vars
  68:3  error  'rawOutput' is defined but never used       no-unused-vars
<script>
import {ref, onMounted, watch } from 'vue';
 /* eslint-disable */ 
import { useAuth0 } from '@auth0/auth0-vue';
import { 
  trackedDomains, 
  newDomain, 
  showRawOutput, 
  rawOutput, 
  loadTrackedDomains, 
  saveTrackedDomains, 
  fetchDomainInfo, 
  removeDomain, 
  refreshDomainInfo 
} from '../utils/domainUtils';

export default {
  setup() {
    const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

     // State for tracking domains
    const trackedDomains = ref([]);
    const newDomain = ref('');
    const showRawOutput = ref(null);
    const rawOutput = ref(null);


    // Load tracked domains from Cloudflare Workers KV on component mount
    onMounted(() => {
      watch(user, (newValue) => {
        if (newValue && newValue.sub) {
          loadTrackedDomains(newValue.sub);
        }
      }, { immediate: true });
    });
 return {
      login: () => loginWithRedirect(),
      logout: () => logout({ returnTo: window.location.origin }),
      isAuthenticated,
      user,
      trackedDomains,
      newDomain,
      fetchDomainInfo: () => fetchDomainInfo(newDomain, trackedDomains, saveTrackedDomains, user),
      saveTrackedDomains: (domainInfo) => saveTrackedDomains(user, domainInfo),
      showRawOutput,
      removeDomain: (domainId) => removeDomain(domainId, trackedDomains, user),
      rawOutput,
      refreshDomainInfo: (domainId) => refreshDomainInfo(domainId, trackedDomains, saveTrackedDomains, user),
    };
  },
};
</script>

<style scoped>
/* Add your styles as needed */
</style>
